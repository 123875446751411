button.delete {
  display: inline-block;
  margin-left: 5px;
  padding: 0;
  width: 15px;
  height: 15px;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 50px;
  border: none;
  outline: none;
  user-select: none;

  &:after {
    content: "X";
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
  }
}

.left {
  left: 30px;
}

.rsw-wrapper {
  min-height: 400px !important;
}

/** Progress Bar */
$progress-height: 5px;
$duration: 3s;

@keyframes stripes {
  from { background-position: 1rem 0 }
  to { background-position: 0 0 }
}

@keyframes rainbow {
  0% { background-color: var(--red) }
  20% { background-color: var(--orange) }
  40% { background-color: var(--yellow) }
  60% { background-color: var(--green) }
  80% { background-color: var(--blue) }
  100% { background-color: var(--indigo) }
}

@keyframes grow {
  from { width: 0% }
  to { width: 100% }
}

.progress-wrapper {
  padding-top: 25%;
}

.progress {
  height: $progress-height;
  overflow: hidden;
}

.progress-bar {
  height: $progress-height;
  width: 100%;

  .loaded & {
      animation: grow $duration ease,
          stripes 1s linear infinite,
          rainbow $duration ease infinite;
  }
}

.animated {
  animation-duration: .8192s;
  animation-fill-mode: backwards;
  transform-style: preserve-3d;
}

/** intro */
@keyframes intro {
  from {
    opacity: 0;
    transform: perspective(500px) translate3d(0, 0, -50px);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.intro {
  animation: intro 1s ease-out;
}

/** enterRight */
@keyframes enterRight {
  from {
    opacity: 0;
    transform: perspective(500px) translate3d(20%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.enterRight {
  animation-name: enterRight;
}

/** enterLeft */
@keyframes enterLeft {
  from {
    opacity: 0;
    transform: perspective(500px) translate3d(-20%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.enterLeft {
  animation-name: enterLeft;
}

/** exitRight */
@keyframes exitRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: perspective(500px) translate3d(100%, 0, -100px);
  }
}

.exitRight {
  animation-name: exitRight;
}

/** exitLeft */
@keyframes exitLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: perspective(500px) translate3d(-100%, 0, -100px);
  }
}

.exitLeft {
  animation-name: exitLeft;
}

.nav {
  margin-bottom: 15px;
  text-align: center;
}

.dot {
  color: black;
  cursor: pointer;
  font-size: 36px;
  line-height: 1;
  margin: 0 15px;
  // opacity: @include 4;
  text-shadow: none;
  transition: opacity 1s ease,
      text-shadow 1s ease;
  will-change: opacity, text-shadow;
}

.active {
  color: var(--blue);
  opacity: 1;
  text-shadow: 0 0px 8px;
}